import { useEffect, useState, useContext, useMemo, useRef } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { LayerDataContext } from "../../../LayerDataContext";
import { PointContext } from "../../../PointContext";
import { AppContext } from "../../../AppContext";
import { RefContext } from "../../../RefContext";
import ReportTable from "../shared/ReportTable";
import settings from "../../../settings.json";
import MapAddition from  '../shared/MapAddition';
import ReportHeader from "../shared/ReportHeader";
import { Row, Col, Container } from "react-bootstrap";
import MapSlider from "../shared/MapSlider";
import ReportFooter from "../shared/ReportFooter";
import { putViewportIntoStorage } from '../../utils'
import {
  Map,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
  CircleLayer,
  Marker,
  Source,
  Layer
} from "react-map-gl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MapLegend from "../shared/MapLegend";
import { faMaximize, faMinimize, faCircle } from "@fortawesome/pro-solid-svg-icons";
import "./table-report.scss";
import { Column } from "jspdf-autotable";

const TableReport = ({
  global,
  reportID,
  api = null,
  apiParam = null,
  filterConfig = undefined,
  hasMarker = false,
  colorParams = null,
  filterID = null,
  reportLayer,
  setReportLayer,
  centerMarker,
  colorArg = null,
  layerID = null,
  locationField = null,
}: any) => {
  
  const [reportData, setReportData] = useState<any>();
  const [tableData, setTableData] = useState<any>();
  const [filteredData, setFilteredData] = useState<any>()
  const [layer, setLayer] = useState<any>()

  const date = filterConfig?.date;
  const setDate = filterConfig?.setDate;
  const dates = filterConfig?.dates;
  const setDates = filterConfig?.setDates;
  let datesArr: any[] = ["All"]

  const { currentPoint } = useContext<any>(PointContext);
  const { setCurrentRef } = useContext<any>(RefContext);
  const FullScreenComp = FullScreen as any;

  const mapRef = useRef<any>();
  const reportBounds = mapRef.current
    ? mapRef.current.getMap().getBounds().toArray().flat()
    : null;

  const fullscreenhandle = useFullScreenHandle() as any;

  const filterData = (date:any, data: any) => {
    if(date === "All") {
      return data
    } else {
      const filtered = data.filter(({ Date }: any) => date === Date);
      return filtered
    }
    
  }



  useEffect(() => {
    if(global.FBFIBDate) {
      const filtered = filterData(global.FBFIBDate, reportData)
      setFilteredData(filtered)
    }
  },[global.FBFIBDate])

  useEffect(() => {

    if (filterConfig && date && reportData) {
      const regex = /date/i;
      let headersArr: any[] = [];
      if (date === 'All') {
        setFilteredData(reportData);
        setLayer(undefined);
        return;
      }
      reportData.forEach((obj: any) => {
        const keys = Object.keys(obj);
        const dateKey = keys.find((dateKey: any) => regex.test(dateKey));
        if (obj[`${dateKey}`] === date) headersArr.push(obj);
      });
      setFilteredData(headersArr);
    }
  }, [date, reportData, filterConfig]);

  useEffect(() => {
    global.setUserLogged(true);
    setCurrentRef(mapRef);
  }, []);

  const flyToPoint = (lng: any, lat: any) => {
    if (mapRef.current && lng && lat) {
      mapRef.current.flyTo({
        center: [lng, lat],
        essential: true,
        zoom: 10,
      });
    }
  };

  const formatDate = (input: any) => {
    const convertUnix = (timestamp: any) => {
      const date = new Date(timestamp);

      const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];

      const day = date.getDate();
      const month = monthNames[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${month} ${year}`;
    };

    const regex = /date/i;
    const keys = Object.keys(input);
    const dateKey: any = keys.find((key: any) => regex.test(key));
    if (dateKey) {
      input[dateKey] = convertUnix(input[dateKey]);
      if (filterConfig) datesArr.push(input[dateKey]);
    }

    return input;
  };

  const fetchReportData = () => {
    global.setLoading(true);
    return api(currentPoint[apiParam])
      .then(({ body }: any) => setReportData(body.map((item: any) => formatDate(item))))
      .catch((error: any) => console.log(error))
      .finally(() => {
        if (filterConfig) {
          setDates(datesArr);
          setDate('All');
        }
        global.setLoading(false);
      });
  };

  useEffect(() => {
    const { lng, lat } = currentPoint;

    if (currentPoint && api) {
      setTimeout(() => flyToPoint(lng, lat), 300);
      flyToPoint(lng, lat);
      fetchReportData();
    } else if (currentPoint) {
      setTimeout(() => flyToPoint(lng, lat), 300);
      setReportData([currentPoint]);
    }
    setLayer(undefined);
  }, [currentPoint]);

  const formatTableColumns = (data: any) => {
    if (!Array.isArray(data) || data.length === 0) {
      return [];
    }

    return Object.keys(data[0])
      .filter(key => !/latitude|longitude|lat|long|Lng|Lat|coord|coordinate/i.test(key))
      .map(key => ({
        Header: key
          .replace(/([a-z])([A-Z])/g, '$1 $2')
          .replace(/_/g, ' ')
          .replace(/\b\w/g, char => char.toUpperCase()),
        accessor: key
      }));
  };

  const tableColumns = useMemo(
    () => tableData ? tableData : undefined,
    [tableData]
  );

  useEffect(() => {
    if (reportData) {
    
      const fTableData: any = formatTableColumns(reportData);
      setTableData(fTableData);
    }
  }, [reportData]);

  const renderTable = (columns: any[], data: any[]) => {
    return <ReportTable customWidth={"100vw"} data={data} columns={columns} />;
  };

  const format = (input: any[]) => {
    if (input.length > 0) {
      return input.map((obj) => {
        const { tableColumns, ...rest } = obj;
        return rest;
      });
    } else {
      return [];
    }
  };

  const handlePoint = (source: string, url: any, pointId: any, arg: any) => {
    setLayer(
      <Source id={`${reportID}-layer`} type="vector" url={url}>
        <Layer {...{
            id: `${reportID}-layer`,
            type: "circle",
            "source-layer": `${source}`,
            paint: {
              "circle-stroke-color": 'white',
              "circle-stroke-width": 2,
              "circle-radius": 12,
              "circle-opacity": 1,
              "circle-stroke-opacity": 1,
              "circle-color": colorParams(arg),
            },
            filter: ['==', ['get', filterID], pointId] as any
          }} 
       />
      </Source>
    )
  }

  useEffect(() => {
    if (currentPoint && filteredData) {
      const keys = Object.keys(currentPoint);
      const keyID: any = keys.find((key: any) => (/id/i).test(key));
      const pointData = filteredData[0];
      const { sourceLayer, tileSet }: any = currentPoint;
      if (filteredData.length === 1) handlePoint(sourceLayer, tileSet, currentPoint[keyID], pointData[colorArg]);
    }
  }, [filteredData, currentPoint, reportData]);

  const pointInPolygonUpdate = (pointsSelected: any) => {
        console.log('pointsSelected', pointsSelected)
        if(pointsSelected){
          setReportData(pointsSelected)
        }
        //setFilteredData(pointsSelected)
        //setUserSelectedLocations(pointsSelected);
        //setMapHoverFilter(['in', ['get', locationField], ['literal', pointsSelected]]);
  }


  return (
    <FullScreenComp handle={fullscreenhandle}>
      <Container id={reportID} className={`table-report-container`}>
        <Col className="col-12 d-flex justify-content-center table-report-container">
          <ReportHeader
            global={global}
            data={reportData && format(reportData)}
            mapRef={mapRef}
            reportID={reportID}
            fullScreenClickHandle={fullscreenhandle.enter}
          />
          <Row>
            <Col className="col-12 table-report-map-container position-relative">
              <Map
                id={`TableReport-map ${reportID}`}
                mapboxAccessToken={settings.maboxKey}
                mapStyle={global.mapStyle}
                preserveDrawingBuffer={true}
                ref={mapRef}
                projection={global.globeView ? "globe" : "mercator" as any}
                onClick={(e) => global.onMapClick(e)}
                onMove={(e) => {
                  putViewportIntoStorage({
                    longitude: e.viewState.longitude,
                    latitude: e.viewState.latitude,
                    zoom: e.viewState.zoom,
                  });
                  global.setViewport({
                    longitude: e.viewState.longitude,
                    latitude: e.viewState.latitude,
                    zoom: e.viewState.zoom,
                  });
                }}
              >
                {centerMarker && currentPoint && currentPoint.lng && currentPoint.lat && (
                  <Marker longitude={currentPoint.lng} latitude={currentPoint.lat}>
                    <FontAwesomeIcon className="marker" icon={faCircle} />
                  </Marker>
                )}
                {layer && layer}
                <MapAddition 
                  global={global} 
                  mapRef={mapRef}
                  position={'low'}
                  zipOff={true}
                  MapSliderAdd={true} 
                  PointInPolygonField={locationField}
                  PointInPolygonUpdate={pointInPolygonUpdate}
                  PointInPolygonLayer={[layerID]}
                  PointInPolygonFeatures={pointInPolygonUpdate}
                />
                <div className="map-legend-container">
                  <MapLegend legendWidth={300} global={global} />
                </div>
              </Map>
            </Col>
          </Row>
          <Row className={`table-row`}>
            <Col className={`col-12 ${reportID}-table-container table-report-table-container`}>
              {tableColumns && reportData && renderTable(tableColumns, reportData)}
            </Col>
            {filteredData && (
              <Col className={`col-12 ${reportID}-table-container table-report-table-container`}>
                {tableColumns && renderTable(tableColumns, filteredData)}
              </Col>
            )}
          </Row>
        </Col>
        <ReportFooter />
      </Container>

    </FullScreenComp>
  );
};

export default TableReport;
