import { Source, Layer, Popup, FillLayer, CircleLayer, LineLayer } from 'react-map-gl';
import settings from "./../../settings.json";
import { useState, useContext, useEffect } from 'react';
import { RefContext } from "./../../RefContext";
import { PointContext } from "./../../PointContext";
import { v4 as uuidv4 } from 'uuid';

const DroughtMonitoring = ({ id, url, opacity, global }: any) => {
  let rangeSel = "nws";  
  let categorySel = `20`;// `14`;  
  let _currentUrl = `https://hazards.fema.gov/arcgis/rest/services/FIRMette/NFHLREST_FIRMette/MapServer/export?bbox={bbox-epsg-3857}&dpi=96&transparent=true&format=png32&layers=show:0,2,4,5,7,8,9,12,13,14,15,18,19,20,21&bboxSR=102100&imageSR=102100&f=image`

  let _imageUrl =  `https://droughtmonitor.unl.edu/data/json/usdm_current.json`
   _imageUrl =  `https://www.ncei.noaa.gov/pub/data/nidis/geojson/na/nadm/NADM-current.geojson`
  // _imageUrl =  `https://www.ncei.noaa.gov/pub/data/nidis/geojson/us/usdo/USDO-Monthly.geojson`


  const [imageUrl, setImageUrl] = useState<any>(_imageUrl);
  const [currentUrl, setCurrentUrl] = useState<any>(_currentUrl);
  const [currentRange, setCurrentRange] = useState<any>(categorySel);

  const { currentRef } = useContext(RefContext);
  const { setCurrentPoint } = useContext<any>(PointContext);
  // @ts-ignore
  const mapInstance = currentRef && currentRef?.current;

  const [category, setCategory] = useState<any>('observed');
  const [categoryMatch, setCategoryMatch] = useState<any>([
                  'match',
                      ['get', 'DROUGHTCAT'], 
                      'd0', `#f4fb18`,
                      'd1', `#f99010`,
                      'd2', `#ea631f`,
                      'd3', `#fa3f19`,
                      'd4', `#ab0707`,
                      "transparent",
              ] );



  //https://www.ncei.noaa.gov/pub/data/nidis/geojson/us/usdm/USDM-current.geojson

  //https://www.ncei.noaa.gov/pub/data/nidis/geojson/us/usdo/USDO-Monthly.geojson


  //https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/json/v1/us/usdo/USDO-Seasonal-current.json?time=1732074300000

  //https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/json/v1/us/usdm/usdm_current.json



  useEffect(() => {
    if(global.layerMode && global.layerMode.layer==='drought'){
      setCategory(global.layerMode.category)
    }
  }, [global.layerMode]);

  useEffect(() => {
    if(category==='observed'){
      setImageUrl(`https://www.ncei.noaa.gov/pub/data/nidis/geojson/na/nadm/NADM-current.geojson`)
      setCategoryMatch([
                  'match',
                      ['get', 'DROUGHTCAT'], 
                      'd0', `#f4fb18`,
                      'd1', `#f99010`,
                      'd2', `#ea631f`,
                      'd3', `#fa3f19`,
                      'd4', `#ab0707`,
                      "transparent",
              ])
    } else if(category==='normal'){
            setCategoryMatch([
                  'match',
                      ['get', 'Outlook'], 
                      'No Drought', `#FFFFFF`,
                      'Development', `#FFDE63`,
                      'Improvement', `#DED2BC`,
                      'Persistence', `#9B634A`,
                      'Removal', `#B2AD69`,
                      "transparent",
              ])
      setImageUrl(`https://www.ncei.noaa.gov/pub/data/nidis/geojson/us/usdo/USDO-Monthly.geojson`)
    } else if(category==='departure'){
            setCategoryMatch([
                  'match',
                      ['get', 'Outlook'], 
                      'No Drought', `#FFFFFF`,
                      'Development', `#FFDE63`,
                      'Improvement', `#DED2BC`,
                      'Persistence', `#9B634A`,
                      'Removal', `#B2AD69`,
                      "transparent",
              ])
      setImageUrl(`https://www.ncei.noaa.gov/pub/data/nidis/geojson/us/usdo/USDO-Seasonal.geojson`)
    } else {
            setCategoryMatch([
                  'match',
                      ['get', 'DROUGHTCAT'], 
                      'd0', `#f4fb18`,
                      'd1', `#f99010`,
                      'd2', `#ea631f`,
                      'd3', `#fa3f19`,
                      'd4', `#ab0707`,
                      "transparent",
              ])
      setImageUrl(`https://www.ncei.noaa.gov/pub/data/nidis/geojson/na/nadm/NADM-current.geojson`)
    }
  }, [category]);


/*

                      ['get', 'DM'], 
                      0, `#f4fb18`,
                      1, `#f99010`,
                      2, `#ea631f`,
                      3, `#fa3f19`,
                      4, `#ab0707`,
                      "transparent",


                      ['get', 'DROUGHTCAT'], 
                      'd0', `#f4fb18`,
                      'd1', `#f99010`,
                      'd2', `#ea631f`,
                      'd3', `#fa3f19`,
                      'd4', `#ab0707`,
                      "transparent",


                      ['get', 'Outlook'], 
                      'No Drought', `#f4fb18`,
                      'Development', `#f99010`,
                      'Improvement', `#ea631f`,
                      'Persistence', `#fa3f19`,
                      'Removal', `#ab0707`,
                      "transparent",
*/

  return (
    <div>
      <Source
        id={`${id}-layerw`}
        key={`${id}-layerw`}
        type="geojson"// @ts-ignore
        data={imageUrl}
      >   
        <Layer
          paint={{
            "fill-opacity": opacity,
            "fill-color": categoryMatch       
          }}
          beforeId={settings.defaultBeforeId}
          type="fill" id={`${id}-layerfll`}
          source={`${id}-layerw`}
        />
       </Source>
    </div>
  )
}

export default DroughtMonitoring